import React, { useState } from "react"

import { Link, graphql } from "gatsby"
import styled from "@emotion/styled"
import SEO from "../components/seo"

import Projects from "../components/projects"
import VideoModal from "../components/videoModal"

const Hero = styled.div`
  min-height: 100%;
`
const StyledH1 = styled.h1``

const StyledProjects = styled(Projects)``

export const PageQuery = graphql`
  query ProjectCategoryQuery {
    allPrismicCategory {
      edges {
        node {
          id
          data {
            slug
            name
            description {
              raw
            }
          }
        }
      }
    }
    allPrismicProject(
      sort: {
        fields: [data___order, last_publication_date]
        order: [DESC, ASC]
      }
    ) {
      edges {
        node {
          uid
          data {
            title {
              raw
            }
            client {
              raw
            }
            client_logo {
              fluid(maxWidth: 500) {
                ...GatsbyPrismicImageFluid_withWebp
              }
            }
            description {
              raw
            }
            hero_image {
              fluid(maxWidth: 5000) {
                ...GatsbyPrismicImageFluid_withWebp
              }
              thumbnails {
                mobile {
                  fluid(maxWidth: 1600) {
                    ...GatsbyPrismicImageFluid_withWebp
                  }
                }
              }
            }
            category {
              document {
                ... on PrismicCategory {
                  data {
                    name
                    slug
                  }
                }
              }
            }
            next_project {
              document {
                ... on PrismicProject {
                  id
                  uid
                  data {
                    title {
                      raw
                    }
                    client {
                      raw
                    }
                    hero_image {
                      fluid(maxWidth: 5000) {
                        ...GatsbyPrismicImageFluid_withWebp
                      }
                      thumbnails {
                        mobile {
                          fluid(maxWidth: 1600) {
                            ...GatsbyPrismicImageFluid_withWebp
                          }
                        }
                      }
                    }
                    client_logo {
                      fluid(maxWidth: 500) {
                        ...GatsbyPrismicImageFluid_withWebp
                      }
                    }
                    category {
                      document {
                        ... on PrismicCategory {
                          id
                          data {
                            slug
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            body {
              ... on PrismicProjectBodyProjectDetails {
                slice_label
                slice_type
                primary {
                  deliverables {
                    raw
                  }
                  content {
                    raw
                  }
                }
              }
              ... on PrismicProjectBodyImage {
                id
                slice_label
                slice_type
                primary {
                  image {
                    fluid(maxWidth: 5000) {
                      ...GatsbyPrismicImageFluid_withWebp
                    }
                    alt
                  }
                }
              }
              ... on PrismicProjectBodyVideo {
                id
                slice_label
                slice_type
                primary {
                  video {
                    embed_url
                    html
                  }
                }
              }
              ... on PrismicProjectBodyQuote {
                id
                slice_label
                slice_type
                primary {
                  author {
                    raw
                  }
                  quote_title {
                    raw
                  }
                  quote_title {
                    raw
                  }
                }
              }
              ... on PrismicProjectBodyText {
                id
                slice_label
                slice_type
                primary {
                  content {
                    raw
                  }
                  text_title {
                    raw
                  }
                }
              }
              ... on PrismicProjectBodyVideos {
                id
                items {
                  aspect_ratio
                  video {
                    html
                    embed_url
                  }
                }
                slice_label
                slice_type
              }
            }
          }
        }
      }
    }
  }
`

export default function Page({
  pageContext,
  data, // this prop will be injected by the GraphQL query below.
}) {
  const [isOpen, setOpen] = useState(false)
  const [filter, setFilter] = useState(pageContext.slug)

  const categories = data.allPrismicCategory.edges
  const projects = data.allPrismicProject.edges

  const filteredProjects = projects.filter(project =>
    filter === ""
      ? true
      : project.node.data.category.document.data.slug === filter
  )

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const onFilterProject = (e, f) => {
    e.preventDefault()
    setFilter(f)
    window && window.history.replaceState({}, "Our work", `/our-work/${f}`)
  }

  return (
    <>
      <SEO
        title={`Our work: ${filter === "" ? "Everything" : pageContext.name}`}
        description="A selection of projects we’ve been working on the last few years."
        image={filteredProjects[0].node.data?.hero_image?.fluid?.src}
      />
      <section className="section-header position-relative">
        <Hero>
          <div className="container-fluid d-flex flex-column justify-content-center">
            <div className="row">
              <div className="col-12 text-center">
                <StyledH1 className="display-2 font-weight-bolder mb-1 mb-md-2 mb-lg-4">
                  <span>Our Work</span>
                </StyledH1>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-7 text-center">
                <p>
                  Below, you’ll find a selection of projects we’ve been working
                  on the last few years.
                  <br />
                  You can also watch&nbsp;
                  <button
                    type="button"
                    className="d-flex-inline align-items-center flex-nowrap m-0 black link--line-under-span-default link--line-under-span"
                    onClick={e => openModal()}
                    id="button"
                  >
                    <span>our showreel</span>.
                  </button>
                </p>
              </div>
            </div>
          </div>
        </Hero>
      </section>

      <section className="bg-white">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="btn-group btn-group--yellow" role="group">
                <Link
                  to="/our-work"
                  aria-current={filter === "" ? "page" : false}
                  className="btn"
                  onClick={e => onFilterProject(e, "")}
                >
                  All
                </Link>
                {categories.map(category => {
                  return (
                    <Link
                      key={category.node.data.slug}
                      to={`/our-work/${category.node.data.slug}`}
                      aria-current={
                        filter === category.node.data.slug ? "page" : false
                      }
                      className="btn"
                      onClick={e => {
                        onFilterProject(e, category.node.data.slug)
                      }}
                    >
                      {category.node.data.name}
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <StyledProjects projects={filteredProjects} />
      <VideoModal
        open={isOpen}
        onCloseModal={closeModal}
        videoId="CQVOyNMq0VI"
      ></VideoModal>
    </>
  )
}

Page.propTypes = {}

Page.defaultProps = {}
